<template>
  <div class="row mx-0 px-0">
    <div class="col-12 col-md-6 px-0 pr-md-2">
      <div class="">
        <BaseLoader v-if="loadingOperator" :is-small="true" />
        <be-multiselect
          v-model="operator_id"
          :inputLabel="$t('assistance.active_operator')"
          :options="selectableOperators"
          :placeholder="$t('assistance.choose_operator')"
          label="label"
          track-by="id"
        >
        </be-multiselect>
      </div>
    </div>
    <div v-if="operator_id" class="w-100px col-12 col-md-6 px-0 pl-md-2">
      <BaseLoader v-if="loadingNumber" :is-small="true" class="pb-2" />
      <div v-else class="mt-4 mt-md-0">
        <be-multiselect
          v-model="index"
          :disabled="disableSelectNumber"
          :inputLabel="$t('assistance.active_number')"
          :options="numbers"
          :placeholder="$t('assistance.active_number_placeholder')"
          label="phone"
          track-by="id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getModelTypeByCode } from "../../helpers/utils";
import BeMultiselect from "../common/BeMultiselect.vue";

export default {
  name: "TicketNumberSelect",
  components: {
    BeMultiselect,
  },
  props: {
    ticketType: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      operator_id: null,
      numbers: [],
      editSearch: true,
      index: null,
      loading: false,
      loadingOperator: true,
      loadingNumber: false,
      timeout: null,
    };
  },

  watch: {
    ticketType() {
      this.loadingOperator = true;
      this.operator_id = null;
      this.timeout = setTimeout(() => (this.loadingOperator = false), 1000);
    },
    operator_id(value) {
      if (value) {
        this.index = null;
        this.getNumbers();
      }
    },
    index(value) {
      if (value !== null && value !== undefined) {
        this.$emit("choosing", {
          model_type: this.modelTicketType,
          model_id: value.id,
          operator_id: this.operator_id.id,
        });
      } else {
        this.$emit("choosing", {
          model_type: null,
          model_id: null,
          operator_id: null,
        });
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["userId", "currency"]),
    ...mapState("assistance", ["operators"]),

    disableSelectNumber() {
      return this.numbers.length === 0;
    },
    modelTicketType() {
      return getModelTypeByCode(this.ticketType.code);
    },
    selectableOperators() {
      return this.operators.filter(o => o.is_depot && o.countries_id);
    },
  },

  mounted() {
    this.loadingOperator = false;
  },

  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },

  methods: {
    getNumbers() {
      this.loadingNumber = true;
      this.index = null;
      this.numbers = [];
      this.editSearch = false;
      this.$store
        .dispatch("assistance/getNumbers", {
          users_id: this.userId,
          operators_id: this.operator_id.id,
          is_confirm: false,
        })
        .then(numbers => {
          this.numbers = numbers;
          this.INITIATE_SELECT_COMPONENT();
        });
    },

    INITIATE_SELECT_COMPONENT() {
      this.timeout = setTimeout(() => {
        window
          .$(".js-operation-placeholder-multiple")
          .select2({
            placeholder:
              this.numbers.length === 0
                ? this.$t("assistance.cant_create_ticket")
                : this.$t("assistance.select_active_number"),
          })
          .change(event => {
            this.index = event.target.value;
          });
        this.loadingNumber = false;
      }, 200);
    },
  },
};
</script>

<style scoped></style>
