<template>
  <div class="row mx-0">
    <div v-if="canSelectService" class="col-12 col-md-6 px-0 pr-md-2">
      <div class="">
        <BaseLoader v-if="loadingService" :is-small="true" />
        <be-multiselect
          v-if="!loadingService"
          v-model="service_id"
          :disabled="!selectableServices.length"
          :inputLabel="$t('assistance.active_service')"
          :options="selectableServices"
          :placeholder="$t('assistance.choose_service')"
          label="label"
          track-by="id"
        />
      </div>
    </div>

    <template v-if="showSearchOperation">
      <BaseLoader v-if="loadingShopping" :is-small="true" class="pb-2" />
      <div
        v-if="service_id"
        class="col-12 col-md-6 px-0 pl-md-2 pt-4 pt-md-0 w-100px"
      >
        <div class="">
          <be-multiselect
            v-if="!loadingShopping"
            v-model="index"
            :disabled="disableSelectOp"
            :inputLabel="$t('assistance.active_operation')"
            :options="remappedOperations"
            :placeholder="$t('assistance.active_operation_placeholder')"
            label="name"
            track-by="id"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
// import OperationOptionLabel from "./OperationOptionLabel";
import BeMultiselect from "../common/BeMultiselect.vue";
import { typeTicketCode } from "../../helpers/constants";

export default {
  name: "TicketShoppingSelect",
  components: {
    // OperationOptionLabel,
    BeMultiselect,
  },
  props: {
    ticketType: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      service_id: null,
      notFoundService: null,
      shoppings: [],
      index: null,
      loadingService: true,
      loadingShopping: false,
      amount: null,
      phone: null,
      timeout: null,
    };
  },

  watch: {
    ticketType() {
      this.loadingService = true;
      this.service_id = null;
      this.timeout = setTimeout(() => (this.loadingService = false), 1000);
    },
    service_id(value) {
      if (value) {
        this.index = null;
        this.getOperations();
      }
    },
    index(value) {
      if (value !== null && value !== undefined) {
        this.$emit("choosing", {
          model_type: this.modelTicketType,
          model_id: value.id,
          api_code: value.api_code,
        });
      } else {
        this.$emit("choosing", {
          model_type: null,
          model_id: null,
          api_code: null,
        });
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["userId", "currency"]),
    ...mapState("shopping", ["services"]),

    disableSelectOp() {
      return this.service_id?.id ? this.shoppings.length === 0 : true;
    },
    modelTicketType() {
      return "Shopping";
    },
    selectableServices() {
      return this.services.filter(
        s => s.type_service.code === this.ticketType.code.toUpperCase()
      );
    },
    canSelectService() {
      return this.ticketType ? !this.$route.query.service_id : true;
    },
    showSearchOperation() {
      return this.ticketType ? !this.$route.query.model_id : true;
    },
    remappedOperations() {
      return this.shoppings.map(item => ({
        ...item,
        name: `${item.created_at}
        | ${this.reference(item)} | ${this.amountOfItem(item)} ${
          this.currency
        } | ${item.status}`,
      }));
    },
  },

  mounted() {
    this.loadingService = false;
    if (this.$route.query.service_id)
      this.service_id = { id: this.$route.query.service_id };
  },

  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },

  methods: {
    getOperations() {
      this.loadingShopping = true;
      this.index = null;
      this.notFoundService = null;
      this.shoppings = [];
      this.$store
        .dispatch("assistance/getOperations", {
          ticketType: this.ticketType,
          field: {
            users_id: this.userId,
            paginate: 40,
            services_id: this.service_id?.id,
          },
        })
        .then(shoppings => {
          this.shoppings = shoppings;
          if (shoppings.length === 0) this.notFoundService = "";
          if (this.$route.query.model_id && this.$route.query.service_id) {
            const index = this.shoppings.findIndex(
              shop =>
                shop.id.toString() === this.$route.query.model_id.toString()
            );
            if (index >= 0) this.index = index;
            else {
              this.$emit("choosing", ...this.$route.query);
            }
          } else this.INITIATE_SELECT_COMPONENT();
        });
    },

    INITIATE_SELECT_COMPONENT() {
      this.timeout = setTimeout(() => {
        window
          .$(".js-shopping-placeholder-multiple")
          .select2({
            placeholder: this.$t("assistance.active_payment_placeholder"),
          })
          .change(event => {
            this.index = event.target.value;
          });
        this.loadingShopping = false;
      }, 200);
    },
    reference(ref) {
      switch (this.ticketType.code) {
        case typeTicketCode.deposit:
          return ref.reference;
        case typeTicketCode.withdraw:
          return ref.client_transaction_id;
        case typeTicketCode.payment:
          return ref.payment_id;
        default:
          return ref.reference;
      }
    },
    amountOfItem(ref) {
      return this.ticketType !== typeTicketCode.deposit &&
        this.ticketType !== typeTicketCode.withdraw &&
        this.ticketType !== typeTicketCode.payment
        ? ref.amount
        : ref.base_amount;
    },
  },
};
</script>

<style scoped></style>
