<template>
  <div class="row mx-0 px-0">
    <div v-if="canSelectOperator" class="col-12 col-md-6 px-0 pr-md-2">
      <div class="">
        <BaseLoader v-if="loadingOperator" :is-small="true" />
        <be-multiselect
          v-model="operator_id"
          :inputLabel="$t('assistance.active_operator')"
          :options="selectableOperators"
          :placeholder="$t('assistance.choose_operator')"
          label="label"
          track-by="id"
        >
        </be-multiselect>
      </div>
    </div>
    <template v-if="!canSendUserId">
      <div class="input-section row col-12 px-0 mx-0 pt-4">
        <div class="col-12 col-md-6 px-0 pr-md-2">
          <BaseInput
            v-model="phone"
            :label="$t('transaction.phone_number')"
            :placeholder="$t('transaction.phone_number_placeholder')"
            type="tel"
          />
        </div>
        <div class="col-12 col-md-6 px-0 pl-md-2 pt-4 pt-md-0">
          <BaseInput
            v-model="amount"
            :label="$t('transaction.payment_amount')"
            :placeholder="$t('transaction.payment_amount_placeholder')"
            rel="any"
            type="number"
          />
        </div>
        <div class="mt-4 px-0 mx-0 row col -12 justify-content-end">
          <button
            :disabled="!amount || !phone || !editSearch"
            :loading="loading"
            :show-loading="true"
            class="be-btn primary blue lg i-left"
            type="button"
            @click.prevent="getOperations(true)"
          >
            {{ $t("common.search") }}
          </button>
        </div>
      </div>
    </template>
    <template v-if="showSearchOperation">
      <div
        v-if="operator_id"
        :class="{ 'pl-md-0': !canSendUserId, 'pl-md-2': canSendUserId }"
        class="w-100px col-12 col-md-6 px-0"
      >
        <BaseLoader v-if="loadingOperation" :is-small="true" class="pb-2" />
        <div v-else class="mt-4 mt-md-0">
          <be-multiselect
            v-model="index"
            :description="operationConcerneDesc"
            :disabled="disableSelectOp"
            :inputLabel="$t('assistance.active_operation')"
            :options="remappedOperations"
            :placeholder="$t('assistance.active_operation_placeholder')"
            label="name"
            track-by="id"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  operationStatus,
  operatorCode,
  typeTicketCode,
} from "../../helpers/constants";
// import OperationOptionLabel from "./OperationOptionLabel";
import { getModelTypeByCode } from "../../helpers/utils";
import BeMultiselect from "../common/BeMultiselect.vue";

export default {
  name: "TicketOperationSelect",
  components: {
    // OperationOptionLabel,
    BeMultiselect,
  },
  props: {
    ticketType: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      operator_id: null,
      operations: [],
      editSearch: true,
      index: null,
      loadingOperator: true,
      loadingOperation: false,
      amount: null,
      phone: null,
      timeout: null,
    };
  },

  watch: {
    ticketType() {
      this.loadingOperator = true;
      this.operator_id = null;
      this.timeout = setTimeout(() => (this.loadingOperator = false), 1000);
    },
    operator_id(value) {
      if (value) {
        this.index = null;
        this.getOperations();
      }
    },
    index(value) {
      if (value !== null && value !== undefined) {
        this.$emit("choosing", {
          model_type: this.modelTicketType,
          model_id: value.id,
          api_code: value.api_code,
          operator_id: this.operator_id.id,
        });
      } else {
        this.$emit("choosing", {
          model_type: null,
          model_id: null,
          api_code: null,
          operator_id: null,
        });
      }
    },
    phone(newValue, oldValue) {
      if (newValue !== oldValue) this.editSearch = true;
    },
    amount(newValue, oldValue) {
      if (newValue !== oldValue) this.editSearch = true;
    },
  },

  computed: {
    ...mapGetters("auth", ["userId", "currency"]),
    ...mapState("assistance", ["operators"]),

    disableSelectOp() {
      return this.operator_id.id ? this.operations.length === 0 : true;
    },
    modelTicketType() {
      return getModelTypeByCode(this.ticketType.code);
    },
    selectableOperators() {
      return this.operators.filter(o =>
        this.ticketType.code === typeTicketCode.payment
          ? true
          : o.code !== operatorCode.bewallet
      );
    },
    isWithdrawTicketType() {
      return this.ticketType.code === typeTicketCode.withdraw;
    },
    canSendUserId() {
      if (this.operator_id?.id) {
        if (this.ticketType.code === typeTicketCode.payment) {
          const op = this.selectableOperators.find(
            o => o.code === "1" && o.id === this.operator_id?.id
          );
          return op !== undefined;
        }
      }
      return true;
    },

    canSelectOperator() {
      return this.ticketType ? !this.$route.query.operator_id : true;
    },
    showSearchOperation() {
      return this.ticketType ? !this.$route.query.model_id : true;
    },
    remappedOperations() {
      return this.operations.map(item => ({
        ...item,
        name: `${item.created_at}
        | ${this.reference(item)} | ${this.amountOfItem(item)} ${
          this.currency
        } | ${item.status}`,
      }));
    },
    operationConcerneDesc() {
      if (this.isWithdrawTicketType) {
        return this.$t("assistance.cant_create_ticket_from_loading_treatment");
      }
      return "";
    },
  },

  mounted() {
    this.loadingOperator = false;
    if (this.$route.query.operator_id)
      this.operator_id = { id: this.$route.query.operator_id };
  },

  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },

  methods: {
    getOperations(force = false) {
      this.loadingOperation = true;
      this.index = null;
      this.operations = [];
      this.editSearch = false;
      if (
        this.canSendUserId ||
        (!this.canSendUserId && this.amount && this.phone)
      )
        this.$store
          .dispatch("assistance/getOperations", {
            ticketType: this.ticketType,
            operator_id: this.operator_id.id,
            field: {
              users_id: this.canSendUserId ? this.userId : null,
              paginate: 80,
              amount: this.amount,
              phone: this.phone,
              is_ticket: true,
            },
            force,
          })
          .then(operations => {
            this.operations = operations.filter(op =>
              this.ticketType.code === typeTicketCode.deposit
                ? op.status !== operationStatus.success && op.op_reference
                : true
            );
            if (this.$route.query.model_id && this.$route.query.operator_id) {
              const index = this.operations.findIndex(
                op => op.id.toString() === this.$route.query.model_id.toString()
              );
              if (index >= 0) this.index = index;
              else {
                this.$emit("choosing", ...this.$route.query);
              }
            } else this.INITIATE_SELECT_COMPONENT();
          });
      else {
        this.loadingOperation = false;
      }
    },

    INITIATE_SELECT_COMPONENT() {
      this.timeout = setTimeout(() => {
        window
          .$(".js-operation-placeholder-multiple")
          .select2({
            placeholder:
              this.operations.length === 0
                ? this.$t("assistance.cant_create_ticket")
                : this.$t("assistance.select_active_operation"),
          })
          .change(event => {
            this.index = event.target.value;
          });
        this.loadingOperation = false;
      }, 200);
    },
    reference(ref) {
      switch (this.ticketType.code) {
        case typeTicketCode.deposit:
          return ref.reference;
        case typeTicketCode.withdraw:
          return ref.client_transaction_id;
        case typeTicketCode.payment:
          return ref.payment_id;
        default:
          return ref.reference;
      }
    },
    amountOfItem(ref) {
      return this.ticketType !== typeTicketCode.deposit &&
        this.ticketType !== typeTicketCode.withdraw &&
        this.ticketType !== typeTicketCode.payment
        ? ref.amount
        : ref.base_amount;
    },
  },
};
</script>

<style scoped></style>
