<template>
  <div class="create-ticket justify-content-center">
    <div class="left-side d-none d-xl-flex">
      <!-- <div class="icon-div">
        <i class="icon-feedback-fill"></i>
      </div> -->
      <div class="left-icon-container-ticket">
        <div class="icon-div-ticket">
          <i class="icon-feedback-fill"></i>
        </div>
      </div>
      <div class="left-icon-text">{{ $t("assistance.reclamation") }}</div>
    </div>
    <div class="right-side">
      <div class="ticket-card">
        <header-with-divider
          :text="$t('assistance.assistance_center')"
          color="blue"
        />
        <div class="p-3 pb-0 mb-0">
          <be-form-error
            v-if="error || !$v.ticketField.model_id.required"
            :message="error || $t('assistance.select_relevant_operation')"
            class="mb-0"
          />
        </div>
        <div class="scroll-wrapper">
          <div class="scroll-content">
            <div :class="ticketCreated ? '' : 'max-height-1px'" class="body">
              <div v-if="ticketCreated" class="ticket-created-success">
                <div class="icon-div">
                  <i class="icon-envelope"></i>
                </div>
                <div class="title">
                  {{ $t("assistance.complaint_saved") }}
                </div>
                <div class="description">
                  <p>{{ $t("assistance.treatment_time") }}</p>
                  <br />
                  <p>{{ $t("assistance.please_wait") }}</p>
                </div>
              </div>
              <form
                v-if="!ticketCreated"
                class="form"
                @submit.prevent="handleTicketForm"
              >
                <div v-if="!this.$route.query.type" class="">
                  <div class="col-lg-6 px-0 pr-md-2">
                    <be-multiselect
                      v-model="ticketField.type_tickets_id"
                      :errorMessage="ticketTypeErrMsg"
                      :idValue="true"
                      :inputLabel="$t('assistance.reclamation_type')"
                      :isInvalid="$v.ticketField.type_tickets_id.$error"
                      :onlyKey="true"
                      :options="ticket_types"
                      :placeholder="
                        $t('assistance.reclamation_type_placeholder')
                      "
                      label="label"
                      showField="label"
                      track-by="id"
                      valueField="id"
                      @blur="$v.ticketField.type_tickets_id.$touch()"
                    >
                    </be-multiselect>
                  </div>
                </div>
                <div v-if="isOperationTicket" class="divider my-30" />
                <div v-if="isShoppingTicket" class="divider my-30" />
                <div v-if="isNumberTicket" class="divider my-30" />
                <TicketOperationSelect
                  v-if="isOperationTicket"
                  :ticketType="type_ticket"
                  @choosing="updateTicketOperation($event)"
                />

                <TicketShoppingSelect
                  v-if="isShoppingTicket"
                  :ticketType="type_ticket"
                  @choosing="updateTicketOperation($event, false)"
                />

                <TicketNumberSelect
                  v-if="isNumberTicket"
                  :ticketType="type_ticket"
                  @choosing="updateTicketOperation($event)"
                />

                <template
                  v-if="
                    type_ticket !== null && type_ticket.ticket_forms.length > 0
                  "
                >
                  <div v-for="(ticketForm, key) in ticketForms" :key="key">
                    <ticket-form-element
                      :ticket-form="ticketForm"
                      @saving="setTicketFormValues($event)"
                    />
                  </div>
                </template>
                <div class="divider my-30" />
                <div class="form-group row">
                  <div class="col">
                    <div class="be-textarea-wrapper">
                      <label
                        :class="{
                          'title-error':
                            $v.ticketField.description.$error &&
                            !$v.ticketField.description.required,
                        }"
                        class="be-textarea-title"
                      >
                        {{ $t("assistance.assistance_desc") }}
                      </label>
                      <textarea
                        v-model="ticketField.description"
                        :class="{
                          'is-invalid': $v.ticketField.description.$error,
                        }"
                        :placeholder="
                          $t('assistance.assistance_desc_placeholder')
                        "
                        class="form-control be-textarea be-scroll"
                        rows="6"
                        @input="$v.ticketField.$touch()"
                      ></textarea>
                      <p
                        v-if="$v.ticketField.description.$error"
                        class="be-textarea-error"
                      >
                        <span v-if="!$v.ticketField.description.required">
                          {{ $t("assistance.insert_ticket_description") }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div v-if="!ticketCreated" class="ticket-card-bottom mt-4">
          <div class="divider" />
          <div class="bottom-btn row justify-content-end">
            <div class="col-sm-auto col-sm pr-3 pr-sm-1">
              <button
                :disabled="$v.$invalid || loading"
                :loading="loading"
                :show-loading="true"
                class="be-btn primary blue lg i-left w-100 w-md-auto justify-content-center"
                @click="handleTicketForm"
              >
                {{ $t("common.submit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketOperationSelect from "../../components/assistance/TicketOperationSelect";
import { serverApiCode, typeTicketCode } from "../../helpers/constants";
import { required, requiredIf } from "vuelidate/lib/validators";
import TicketShoppingSelect from "../../components/assistance/TicketShoppingSelect";
import TicketFormElement from "../../components/assistance/TicketFormElement";
import HeaderWithDivider from "../../components/common/HeaderWithDivider";
import BeMultiselect from "../../components/common/BeMultiselect.vue";
import BeFormError from "../../components/common/BeFormError";
import Vue from "vue";
import TicketNumberSelect from "../../components/assistance/TicketNumberSelect";

export default {
  name: "AssistanceForm",
  components: {
    TicketNumberSelect,
    TicketFormElement,
    TicketShoppingSelect,
    TicketOperationSelect,
    HeaderWithDivider,
    BeMultiselect,
    BeFormError,
  },
  props: {
    ticket_types: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      ticketCreated: false,
      ticketField: {
        type_tickets_id: null,
        operators_id: null,
        api_code: null,
        model_id: null,
        model_type: null,
        form_values: [],
        description: null,
      },
    };
  },

  computed: {
    type_ticket() {
      if (this.ticketField.type_tickets_id) {
        const type = this.ticket_types.find(
          t => t.id === parseInt(this.ticketField.type_tickets_id)
        );
        return type !== undefined ? type : null;
      } else return null;
    },
    isOperationTicket() {
      if (this.type_ticket) {
        return (
          this.type_ticket.code === typeTicketCode.deposit ||
          this.type_ticket.code === typeTicketCode.payment ||
          this.type_ticket.code === typeTicketCode.withdraw
        );
      } else return false;
    },
    isNumberTicket() {
      if (this.type_ticket) {
        return this.type_ticket.code === typeTicketCode.number;
      } else return false;
    },
    isShoppingTicket() {
      if (this.type_ticket) {
        const code = this.type_ticket.code.toUpperCase();
        return (
          code === typeTicketCode.charge_card ||
          code === typeTicketCode.recharge ||
          code === typeTicketCode.sell_card ||
          code === typeTicketCode.invoice ||
          code === typeTicketCode.subscription ||
          code === typeTicketCode.voyage
        );
      } else return false;
    },
    haveFormField() {
      return this.ticketForms.length > 0;
    },
    ticketTypeErrMsg() {
      if (this.$v.ticketField.type_tickets_id.$error) {
        if (!this.$v.ticketField.type_tickets_id.required) {
          return this.$t("assistance.select_ticket_type");
        }
      }
      return "";
    },

    ticketForms() {
      if (this.type_ticket) {
        return this.type_ticket.ticket_forms.filter(ticketForm => {
          if (ticketForm.is_active) {
            if (
              ticketForm.operators_id === null &&
              ticketForm.api_code === null
            )
              return true;
            else {
              if (
                ticketForm.operators_id !== null &&
                ticketForm.operators_id === this.ticketField.operators_id
              )
                return true;
              else {
                return (
                  ticketForm.api_code !== null &&
                  ticketForm.api_code === this.ticketField.api_code
                );
              }
            }
          } else return false;
        });
      } else return [];
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    (async () => {
      await Vue.loadScript(
        `${window.location.origin}/assets/plugins/select2/js/select2.full.min.js`
      );
    })();
    if (this.$route.query.type) {
      const type = this.ticket_types.find(
        t => t.code === this.$route.query.type
      );
      if (type !== undefined) {
        this.ticketField.type_tickets_id = type;
      }
    }
  },

  methods: {
    updateTicketOperation(data, isOp = true) {
      this.ticketField.model_id = data.model_id;
      this.ticketField.model_type = data.model_type;
      this.ticketField.api_code = data.api_code;
      if (isOp) this.ticketField.operators_id = data.operator_id;
    },

    setTicketFormValues(data) {
      let result = {};
      result[data.code] = data.valeur;
      const index = this.ticketField.form_values.findIndex(tfv =>
        // eslint-disable-next-line no-prototype-builtins
        tfv.hasOwnProperty(data.code)
      );
      if (index >= 0) {
        this.ticketField.form_values[index] = result;
      } else this.ticketField.form_values.push(result);
    },

    handleTicketForm() {
      if (this.isValidForm()) {
        this.loading = true;
        this.$store
          .dispatch("assistance/addNewTicket", {
            ...this.ticketField,
            form_values: JSON.stringify(this.ticketField.form_values),
          })
          .then(() => {
            // this.$router.push({ name: "dashboard" });
            this.loading = false;
            this.ticketCreated = true;
          })
          .catch(error => {
            if (error.message) this.error = error.message;
            this.loading = false;
          });
      }
    },

    isValidForm() {
      this.$v.$touch();
      this.error = null;
      if (this.$v.invalid) return false;
      let valid = true;
      this.ticketForms.forEach(tf => {
        const tfv = this.ticketField.form_values.find(tfv =>
          // eslint-disable-next-line no-prototype-builtins
          tfv.hasOwnProperty(tf.code)
        );
        if (tfv !== undefined) {
          if (tfv[tf.code]?.toString().length > 1) {
            if (
              tf.api_code === serverApiCode.abyster &&
              tf.code === "reference"
            ) {
              valid = tfv[tf.code]?.toString().length === 13;
              if (!valid)
                this.error = this.$t("assistance.ticket_invalid_ref_length");
            }
            if (
              tf.api_code === serverApiCode.blockchain &&
              tf.code === "hash"
            ) {
              valid = tfv[tf.code]?.toString().length >= 60;
              if (!valid)
                this.error = this.$t("assistance.ticket_invalid_hash_length");
            }
          } else {
            valid = false;
            this.error = this.$t("assistance.ticket_field_required");
          }
        } else {
          valid = false;
          this.error = this.$t("assistance.ticket_field_required");
        }
      });
      return valid;
    },
  },

  validations() {
    return {
      ticketField: {
        type_tickets_id: { required },
        description: { required },
        model_id: {
          required: requiredIf(
            () => this.isOperationTicket || this.isShoppingTicket
          ),
        },
        model_type: {
          required: requiredIf(
            () => this.isOperationTicket || this.isShoppingTicket
          ),
        },
        form_values: {
          required: requiredIf(() => this.haveFormField),
        },
      },
    };
  },
};
</script>

<style scoped></style>
