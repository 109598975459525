<template>
  <div class="image-upload">
    <div class="image-edit">
      <input
        type="file"
        id="imageUpload"
        accept=".png, .jpg, .jpeg"
        @change="onFileChange($event)"
        :disabled="!isActive"
        :required="isRequired"
      />
      <label for="imageUpload" class="text-center f-w-600 f-16 text-dark">
        <i v-if="!loading" class="feather icon-camera"></i>
        <i v-if="loading" class="fas fa-spinner fa-pulse"></i>
      </label>
    </div>
    <div class="image-preview">
      <div
        id="imagePreview"
        :style="'background-image: url(' + imageUrl + ');'"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketImageUploader",

  props: {
    oldImage: { type: String, default: null },
    name: { type: String, default: "document" },
    isRequired: { type: Boolean, default: true },
    isActive: { type: Boolean, default: true }
  },

  data() {
    return {
      loading: false,
      imageUrl: "https://via.placeholder.com/300?text=Image",
      image: null
    };
  },

  watch: {
    oldImage(value) {
      this.imageUrl =
        value === null
          ? "https://via.placeholder.com/300?text=Image"
          : this.oldImage;
    }
  },

  methods: {
    onFileChange(event) {
      if (event.target.files && event.target.files[0]) {
        this.loading = true;
        let formData = new FormData();
        const file = event.target.files[0];
        formData.append(this.name, file);
        this.$store
          .dispatch("assistance/uploadImage", formData)
          .then(data => {
            this.$emit("uploaded", data);
            let reader = new FileReader();
            reader.onload = e => {
              this.imageUrl = e.target.result;
              window.$("#imagePreview").hide();
              window.$("#imagePreview").fadeIn(800);
            };
            reader.readAsDataURL(event.target.files[0]);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style scoped>
.image-upload {
  position: relative;
  max-width: 205px;
}

.image-upload .image-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.image-upload .image-edit input {
  display: none;
}

.image-upload .image-edit input + label {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-bottom: 0;
  padding-top: 5.5px;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.image-upload .image-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.image-upload .image-edit input + label:after {
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.image-upload .image-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.image-upload .image-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
