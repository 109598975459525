import { render, staticRenderFns } from "./TicketShoppingSelect.vue?vue&type=template&id=5028f36a&scoped=true&"
import script from "./TicketShoppingSelect.vue?vue&type=script&lang=js&"
export * from "./TicketShoppingSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5028f36a",
  null
  
)

export default component.exports