<template>
  <div class="form-group row">
    <label class="col-lg-4 col-form-label text-lg-right"
      >{{ ticketForm.label }}
      <span v-if="ticketForm.is_require" class="text-danger"> *</span>
    </label>
    <div
      class="col-lg-7"
      v-if="ticketForm.type !== 'image' && ticketForm.type !== 'datetime'"
    >
      <input
        :type="ticketForm.type === 'numeric' ? 'number' : ticketForm.type"
        :placeholder="ticketForm.placeholder"
        v-model="response.valeur"
        :required="ticketForm.is_require"
        class="form-control"
        :class="{ 'is-invalid': error }"
      />
    </div>
    <div class="col-lg-7" v-if="ticketForm.type === 'image'">
      <ticket-image-uploader
        @uploaded="onFileChange($event)"
        :old-image="response.valeur"
        :is-active="true"
      />
    </div>
    <div
      class="col-lg-7"
      v-if="ticketForm.type === 'datetime' || ticketForm.type === 'date'"
    >
      <date-picker
        style="width: 100%"
        :type="ticketForm.type"
        :id="ticketForm.code"
        :placeholder="ticketForm.placeholder"
        :required="ticketForm.is_require"
        input-class="form-control shadow-sm"
        :lang="$moment.locale()"
        :disabled-date="disableDate"
        :format="
          ticketForm.type === 'date' ? 'DD-MM-YYYY' : 'DD-MM-YYYY H:mm:ss'
        "
        v-model="dateValue"
        :class="{ 'is-invalid': error }"
      />
    </div>
  </div>
</template>

<script>
import TicketImageUploader from "./TicketImageUploader";
import DatePicker from "vue2-datepicker";

export default {
  name: "TicketFormElement",
  components: { TicketImageUploader, DatePicker },
  props: {
    ticketForm: { type: Object, required: true },
    oldValue: { type: String, default: null },
    error: { default: false },
  },

  data() {
    return {
      loadingPhoto: false,
      response: {
        code: this.ticketForm.code,
        valeur: null,
      },
      dateValue: null,
    };
  },

  watch: {
    response: {
      deep: true,
      handler(value) {
        this.$emit("saving", value);
      },
    },
    dateValue(value) {
      if (value) {
        if (this.ticketForm.type === "date")
          this.response.valeur =
            value.getFullYear() +
            "-" +
            (value.getMonth() + 1) +
            "-" +
            value.getDate();
        else
          this.response.valeur =
            value.getFullYear() +
            "-" +
            (value.getMonth() + 1) +
            "-" +
            value.getDate() +
            " " +
            value.getHours() +
            ":" +
            value.getMinutes() +
            ":" +
            value.getSeconds();
      } else this.response.valeur = null;
    },
  },

  mounted() {
    if (this.oldValue) this.response.valeur = this.oldValue;
    else this.$emit("saving", { valeur: null, code: this.ticketForm.code });
  },

  methods: {
    disableDate(date) {
      return date > new Date();
    },

    onFileChange(event) {
      this.response.valeur = event.chemin;
    },
  },
};
</script>
