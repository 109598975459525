<template>
  <div
    class="be-error-alert m-b-30 d-flex align-items-center"
    role="alert"
    v-if="message && show"
  >
    <i class="icon-error-fill" @click="closeAlert"></i> {{ message }}
  </div>
</template>

<script>
export default {
  name: "BeFormError",
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    closeAlert() {
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
